// router setup
import routes from '../routes/routes'

import Vue from 'vue'
import VueRouter from 'vue-router'

import initProgress from '../progressbar';

Vue.router = new VueRouter({
    routes, // short for routes: routes
    linkActiveClass: 'active',
    scrollBehavior: (to) => {
        if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    }
})

initProgress(Vue.router);

export default Vue.router;