<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">使用者資料編輯</h4>
    </div>
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-md-12">
            <fg-input
              type="text"
              label="使用者帳號"
              :disabled="true"
              placeholder="使用者帳號"
              v-model="user.account"
            >
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <fg-input
              type="text"
              label="使用者名稱"
              placeholder="使用者名稱"
              v-model="user.name"
            >
            </fg-input>
          </div>
        </div>

        <div class="text-center">
          <button
            type="submit"
            class="btn btn-info btn-fill btn-wd"
            @click.prevent="updateProfile"
          >
            更新使用者資料
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: {
        account: "",
        name: "",
      },
    };
  },
  methods: {
    updateProfile() {
      this.$http
        .post(`/users/info`, {
          account: this.user.account,
          name: this.user.name,
        })
        .then(
          (res) => {
            this.$notify({
              title: "更新使用者資料成功",
              message: res.data.msg,
              verticalAlign: "bottom",
              icon: "nc-icon nc-check-2",
              type: "success",
            });
          },
          (res) => {
            this.$notify({
              title: "更新使用者資料失敗",
              message: res.response.data.msg,
              verticalAlign: "bottom",
              icon: "nc-icon nc-alert-circle-i",
              type: "danger",
            });
          }
        );
    },
  },
  mounted() {
    const { user } = this.$auth.user();
    this.user.account = user.account;
    this.user.name = user.name;
  },
};
</script>
<style></style>
