<template>
  <div class="user">
    <div class="photo">
      <img src="static/img/faces/face-1.jpg" alt="user avatar" />
    </div>
    <div class="info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click="toggleMenu"
        href="#"
      >
        <span>
          {{ _user.name }}
          <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav nav-menu" v-show="!isClosed">
            <li>
              <a href="#" @click.prevent="userAccount">
                <span class="sidebar-mini-icon">Ep</span>
                <span class="sidebar-normal">使用者編輯</span>
              </a>
            </li>
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  components: {
    CollapseTransition,
  },
  data() {
    return {
      isClosed: true,
    };
  },
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
    userAccount() {
      this.$router.push({ name: "user-account" }).catch(() => {});
    },
  },
  computed: {
    _user() {
      const { user } = this.$auth.user();
      return user || {};
    },
  },
};
</script>
<style scoped>
.nav.nav-menu {
  margin-top: 0;
}
</style>
