<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">使用者修改密碼</h4>
    </div>
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-md-12">
            <fg-input
              type="password"
              label="舊密碼"
              placeholder="舊密碼"
              v-model="user.old_password"
            >
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <fg-input
              type="password"
              label="新密碼"
              placeholder="新密碼"
              v-model="user.new_password"
            >
            </fg-input>
          </div>
        </div>

        <div class="text-center">
          <button
            type="submit"
            class="btn btn-info btn-fill btn-wd"
            @click.prevent="updateProfile"
          >
            更新使用者資料
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: {
        account: "",
        old_password: "",
        new_password: "",
      },
    };
  },
  methods: {
    updateProfile() {
      this.$http
        .post(`/users/password`, {
          account: this.user.account,
          old_password: this.user.old_password,
          new_password: this.user.new_password,
        })
        .then(
          (res) => {
            this.$notify({
              title: "修改密碼成功",
              message: res.data.msg,
              verticalAlign: "bottom",
              icon: "nc-icon nc-check-2",
              type: "success",
            });
          },
          (res) => {
            this.$notify({
              title: "修改密碼失敗",
              message: res.response.data.msg,
              verticalAlign: "bottom",
              icon: "nc-icon nc-alert-circle-i",
              type: "danger",
            });
          }
        );
    },
  },
  mounted() {
    const { user } = this.$auth.user();
    this.user.account = user.account;
  },
};
</script>
<style></style>
