import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// Dashboard pages
const Overview = () => import(/* webpackChunkName: "overview" */ 'src/components/Dashboard/Views/Dashboard/Overview.vue')

// Profile
import User from 'src/components/Dashboard/Views/Profile/UserProfile.vue'

// Login
import Login from 'src/components/Dashboard/Views/Login/Login.vue'

// Users
const UsersTable = () => import(/* webpackChunkName: "users" */ 'src/components/Dashboard/Views/Users/UsersTables.vue');
// Record Face
const RecordFaceLogTable = () => import(/* webpackChunkName: "record-face" */ 'src/components/Dashboard/Views/RecordFace/RecordFaceLogTables.vue');
// Stranger
const StrangerLogTable = () => import(/* webpackChunkName: "stranger" */ 'src/components/Dashboard/Views/Stranger/StrangerLogTables.vue');
// Stranger Register
const StrangerRegisterLogTable = () => import(/* webpackChunkName: "stranger" */ 'src/components/Dashboard/Views/StrangerRegister/StrangerRegisterLogTables.vue');
// Work Time
const WorkTimeLogTable = () => import(/* webpackChunkName: "worktime" */ 'src/components/Dashboard/Views/WorkTime/WorkTimeLogTables.vue');
// Work Time Attendance
const WorkTimeAttendanceLogTable = () => import(/* webpackChunkName: "worktime" */ 'src/components/Dashboard/Views/WorkTimeAttendance/WorkTimeAttendanceLogTables.vue');
// Detect
const DetectLogTable = () => import(/* webpackChunkName: "detect" */ 'src/components/Dashboard/Views/Detect/DetectLogTables.vue');
// Queue
const QueueTable = () => import(/* webpackChunkName: "queue" */ 'src/components/Dashboard/Views/Queue/QueueTables.vue');
// Log
const LogTable = () => import(/* webpackChunkName: "log" */ 'src/components/Dashboard/Views/Log/LogTables.vue');
// Import Export
const ImportExport = () => import(/* webpackChunkName: "import-export" */ 'src/components/Dashboard/Views/ImportExport/ImportExport.vue')
// Setting
const Setting = () => import(/* webpackChunkName: "setting" */ 'src/components/Dashboard/Views/Setting/SettingTables.vue');


let settingPage = {
  path: '/setting',
  component: DashboardLayout,
  children: [
    {
      path: '',
      name: 'setting',
      component: Setting,
      meta: {
        auth: true
      }
    },
  ]
}

let importExportPage = {
  path: '/import-export',
  component: DashboardLayout,
  children: [
    {
      path: '',
      name: 'import-export',
      component: ImportExport,
      meta: {
        auth: true
      }
    },
  ]
}

let logPage = {
  path: '/log',
  component: DashboardLayout,
  redirect: '/log/table',
  children: [
    {
      path: 'table',
      name: 'log-table',
      component: LogTable,
      meta: {
        auth: true
      }
    },
  ]
}

let workTimePage = {
  path: '/work/time',
  component: DashboardLayout,
  redirect: '/work/time/table',
  children: [
    {
      path: 'table',
      name: 'worktime-table',
      component: WorkTimeLogTable,
      meta: {
        auth: true
      }
    },
  ]
}

let workTimeAttendancePage = {
  path: '/work-attendance/time',
  component: DashboardLayout,
  redirect: '/work-attendance/time/table',
  children: [
    {
      path: 'table',
      name: 'worktime-attendance-table',
      component: WorkTimeAttendanceLogTable,
      meta: {
        auth: true
      }
    },
  ]
}

let detectPage = {
  path: '/detect',
  component: DashboardLayout,
  redirect: '/detect/table',
  children: [
    {
      path: 'table',
      name: 'detect-table',
      component: DetectLogTable,
      meta: {
        auth: true
      }
    },
  ]
}

let queuePage = {
  path: '/queue',
  component: DashboardLayout,
  redirect: '/queue/table',
  children: [
    {
      path: 'table',
      name: 'queue-table',
      component: QueueTable,
      meta: {
        auth: true
      }
    },
  ]
}

let strangerPage = {
  path: '/stranger',
  component: DashboardLayout,
  redirect: '/stranger/table',
  children: [
    {
      path: 'table',
      name: 'stranger-log-table',
      component: StrangerLogTable,
      meta: {
        auth: true
      }
    },
  ]
}

let strangerRegisterPage = {
  path: '/stranger/register',
  component: DashboardLayout,
  redirect: '/stranger/register/table',
  children: [
    {
      path: 'table',
      name: 'stranger-register-log-table',
      component: StrangerRegisterLogTable,
      meta: {
        auth: true
      }
    },
  ]
}

let recordFacePage = {
  path: '/record-face',
  component: DashboardLayout,
  redirect: '/record-face/table',
  children: [
    {
      path: 'table',
      name: 'record-face-log-table',
      component: RecordFaceLogTable,
      meta: {
        auth: true
      }
    },
  ]
}

let usersPage = {
  path: '/users',
  component: DashboardLayout,
  redirect: '/users/table',
  children: [
    {
      path: 'table',
      name: 'users-table',
      component: UsersTable,
      meta: {
        auth: true
      }
    },
  ]
}

let userPage = {
  path: '/user',
  component: DashboardLayout,
  redirect: '/user/account',
  children: [
    {
      path: 'account',
      name: 'user-account',
      component: User,
      meta: {
        auth: true
      },
    },
  ]
}

let loginPage = {
  path: '/login',
  name: 'auth-login',
  component: Login,
  meta: {
    auth: false
  }
}

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
  },
  settingPage,
  importExportPage,
  logPage,
  queuePage,
  detectPage,
  workTimePage,
  workTimeAttendancePage,
  strangerPage,
  recordFacePage,
  strangerRegisterPage,
  usersPage,
  userPage,
  loginPage,
  {
    path: '/dashboard',
    component: DashboardLayout,
    redirect: '/dashboard/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview,
        meta: {
          auth: true
        },
      },
    ]
  },
  { path: '*', component: NotFound }
];

export default routes
