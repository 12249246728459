<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left"></nav>
      <div class="copyright pull-right">
        <i class="fa fa-desktop"></i> {{ frontVer }}
        <i class="fa fa-server server"></i> {{ backVer }}
        - 版權所有 &copy; 系統開發
        <i class="fa fa-heart heart"></i> by
        <a href="https://skysharp.com.tw" target="_blank" rel="noopener"
          >Skysharp</a
        >.
      </div>
    </div>
  </footer>
</template>
<script>
import { version } from "../../../../package";
import axios from "axios";

export default {
  data() {
    return {
      frontVer: version,
      backVer: "",
    };
  },
  mounted() {
    this.getVersion();
  },
  methods: {
    getVersion() {
      axios.get(`/version`).then((res) => {
        this.backVer = res.data;
      });
    },
  },
};
</script>
<style></style>
