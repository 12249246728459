import Vue from 'vue'
import VueI18n from 'vue-i18n'
import http from './http'
import store from './store'
import router from './router'
import config from './config'
import './pollyfills'
import VueRouter from 'vue-router'
import VueNotify from 'vue-notifyjs'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import App from './App.vue'

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'

// library imports
import './assets/sass/paper-dashboard.scss'
import './assets/sass/demo.scss'

import sidebarLinks from './sidebarLinks'
import './registerServiceWorker'
import i18n from './i18n'

// plugin setup
Vue.use(VueRouter)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)
Vue.use(VueNotify)
Vue.use(VueI18n)
Vue.use(SideBar, { sidebarLinks: sidebarLinks })
locale.use(lang)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  http: http,
  store: store,
  router: router,
  config: config,
  i18n,
  render: h => h(App),
})
