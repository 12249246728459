export default [
  {
    name: 'dashboard',
    icon: 'nc-icon nc-bank',
    path: '/dashboard/overview'
  },
  {
    name: 'userList',
    icon: 'nc-icon nc-badge',
    path: '/users/table'
  },
  {
    name: 'faceRecognitionRecords',
    icon: 'nc-icon nc-circle-10',
    path: '/record-face/table'
  },
  {
    name: 'strangerRecognitionRecords',
    icon: 'nc-icon nc-single-02',
    path: '/stranger/table'
  },
  {
    name: 'strangerRealNameRegistrationRecords',
    icon: 'nc-icon nc-single-copy-04',
    path: '/stranger/register/table'
  },
  {
    name: 'workTimeRecords',
    icon: 'nc-icon nc-single-copy-04',
    path: '/work/time/table'
  },
  {
    name: 'workTimeAttendanceRecords',
    icon: 'nc-icon nc-single-copy-04',
    path: '/work-attendance/time/table'
  },
  {
    name: 'detectionTriggerRecords',
    icon: 'nc-icon nc-vector',
    path: '/detect/table'
  },
  {
    name: 'backgroundThreads',
    icon: 'nc-icon nc-user-run',
    path: '/queue/table'
  },
  {
    name: 'logs',
    icon: 'nc-icon nc-paper',
    path: '/log/table'
  },
  {
    name: 'importExport',
    icon: 'nc-icon nc-app',
    path: '/import-export'
  },
  {
    name: 'systemSettings',
    icon: 'nc-icon nc-settings-gear-65',
    path: '/setting'
  },
]
