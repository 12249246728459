<template>
  <div class="row">
    <div class="col-lg-12">
      <edit-profile-form> </edit-profile-form>
      <edit-password-form> </edit-password-form>
    </div>
  </div>
</template>
<script>
import EditProfileForm from "./EditProfileForm.vue";
import EditPasswordForm from "./EditPasswordForm.vue";

export default {
  components: {
    EditProfileForm,
    EditPasswordForm,
  },
};
</script>
<style></style>
