<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="login">
                <card type="login">
                  <h3 slot="header" class="header text-center">
                    {{ $t("login.title") }}
                  </h3>

                  <fg-input
                    v-model="form.body.account"
                    addon-left-icon="nc-icon nc-single-02"
                    placeholder="帳號"
                  ></fg-input>

                  <fg-input
                    v-model="form.body.password"
                    addon-left-icon="nc-icon nc-key-25"
                    placeholder="密碼"
                    type="password"
                  ></fg-input>

                  <br />

                  <p-button
                    native-type="submit"
                    slot="footer"
                    type="warning"
                    round
                    block
                    class="mb-3"
                    >登入</p-button
                  >
                </card>
              </form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div
          class="full-page-background"
          style="background-image: url(static/img/background/background-2.jpg)"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Checkbox, Button } from "src/components/UIComponents";
import AppNavbar from "./../Layout/AppNavbar";
import AppFooter from "./../Layout/AppFooter";

export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
  },

  mounted() {
    console.log(this.$auth.redirect());
  },

  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },

    login() {
      // handle login here
      this.$auth
        .login({
          data: {
            account: this.form.body.account,
            password: this.form.body.password,
          },
          remember: false,
          fetchUser: true,
          staySignedIn: false,
        })
        .then(
          (res) => {
            this.$notify({
              title: "登入成功",
              message: res.data.msg,
              verticalAlign: "bottom",
              icon: "nc-icon nc-check-2",
              type: "success",
            });
          },
          (res) => {
            this.$notify({
              title: "登入失敗",
              message: res.response.data.msg,
              icon: "nc-icon nc-alert-circle-i",
              type: "danger",
            });
          }
        );
    },
  },
  data() {
    return {
      form: {
        body: {
          account: "",
          password: "",
        },
        errors: {},
      },
    };
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style></style>
