<template>
  <footer class="footer footer-black footer-white">
    <div class="container-fluid">
      <div class="row">
        <nav class="footer-nav"></nav>
        <div class="credits ml-auto">
          <div class="copyright">
            版權所有 &copy; 系統開發
            <i class="fa fa-heart heart"></i> by
            <a href="https://skysharp.com.tw" target="_blank" rel="noopener"
              >Skysharp</a
            >.
          </div>

          <div class="copyright">
            <div :text="$t('language.select')" right>
              <a href="#" @click="selectLanguage('en')">
                EN
              </a>
              <a href="#" @click="selectLanguage('zh')">
                ZH-TW
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "main-footer",
  methods: {
    selectLanguage(language) {
      this.$i18n.locale = language;
      localStorage.setItem("locale", language);
    },
  },
};
</script>
<style></style>
